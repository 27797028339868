<template>
  <div class="operationBtns">
    <el-button
      v-for="(btn, index) in btnList"
      :key="index"
      v-show="handleHidden(btn)"
      v-text="handleText(btn)"
      v-debounceClick="() => btn.handle(row, col)"
      :disabled="handleDisabled(btn)"
      :class="{ del: ['删除', '禁用'].includes(handleText(btn)) }"
      type="text"
    />
  </div>
</template>

<script>
import { Button } from 'element-ui';
import Vue from 'vue';
Vue.use(Button);

export default {
  name: 'operationBtns',

  props: {
    row: { type: Object },

    col: { type: Object },

    btnList: {
      type: Array,
      default: () => [
        {
          text: 'text',
          textHandle: () => {},
          handle: () => {},
        },
      ],
    },
  },

  computed: {
    handleDisabled() {
      return (btn) => {
        return btn.disabled?.(this.row);
      };
    },

    handleHidden() {
      return (btn) => {
        return btn.hidden ? !btn.hidden?.(this.row) : true;
      };
    },

    handleText() {
      return (btn) => {
        if (!btn.textHandle) return btn.text;
        else return btn.textHandle(this.row);
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.operationBtns {
  display: flex;
  gap: 16px;

  .el-button--text {
    @apply text-F1;
    border-color: transparent;
    background: none;

    &.is-disabled {
      @apply text-F3;
    }

    &:not(.is-disabled):hover {
      @apply text-theme;
    }

    &:not(.is-disabled).del:hover {
      @apply text-error;
    }
  }

  .el-button + .el-button {
    margin: 0;
  }
}
</style>
