<template>
  <div class="ql-empty">
    <img alt="empty" :src="src" :class="className" />
    <p class="T5 text-F2">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'ql-empty',

  computed: {
    src() {
      if (this.$attrs.type) {
        return require(`@images/components/noData/${this.$attrs.type}.png`);
      }

      return require('@images/components/noData/noData.png');
    },

    className() {
      if (this.$attrs.type) {
        if (this.$attrs.type.startsWith('global')) {
          return 'global';
        } else if (this.$attrs.type.startsWith('module')) {
          return 'module';
        }
      }

      return '';
    },

    text() {
      if (this.$attrs.text) {
        return this.$attrs.text;
      }

      if (this.$attrs.type) {
        return {
          'global-not_content': '暂无内容',
          'global-not_message': '暂无消息',
          'global-not_network': '无网络',
          'global-not_found': '页面出错了',
          'global-not_schedule': '暂无课表',
          'global-code_expired': '二维码已过期',
          'global-not_permission': '暂无权限',
          'global-not_notice': '暂无公告',
          'global-function_developing': '此功能正在开发中',
          'global-not_search_content': '暂无搜索内容',
          'global-not_group': '暂无分组',
          'global-not_homework': '暂无作业',

          'module-not_content': '暂无内容',
          'module-not_data': '暂无数据',
          'module-img_loading': '图片加载中',
          'module-img_error': '图片加载失败',
          'module-not_homework': '暂无作业',
          'module-not_notice': '暂无公告',
          'module-video_error': '视频画面异常',
          'module-not_permission': '暂无权限',
        }[this.$attrs.type];
      }

      return '暂无内容，空空如也~';
    },
  },
};
</script>

<style lang="scss" src="./index.scss" scoped></style>
