import _ from 'lodash';

import Vue from 'vue';
import { Pagination, Table, TableColumn, Checkbox } from 'element-ui';
import HeaderFilter from './components/headerFilter.vue';
import NoData from '@components/noData/index.vue';

Vue.use(Pagination);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Checkbox);

export default {
  name: 'tableComponent',

  props: {
    height: {
      type: [String, Number],
    },

    maxHeight: {
      type: [String, Number],
      default: 650,
    },

    showExpand: {
      type: Boolean,
    },

    data: {
      type: Array,
      default: () => [],
    },

    selectedRows: {
      type: Array,
    },
    rowKey: {
      type: String,
      default: 'id',
    },

    columns: {
      type: Array,
      default: () => [],
    },

    pagination: {
      type: Object,
      default: () => ({
        page: 1,
        pageSize: 10,
        total: 0,
      }),
    },
  },

  components: {
    HeaderFilter,
    NoData,
  },

  data() {
    return {
      offsetTop: 0,
      maxRow: 5,
      expand: false,

      isSelectAll: false,
    };
  },

  methods: {
    handleFilter(filterObj) {
      this.$emit('filter-change', filterObj);
    },

    toggleExpand() {
      this.expand = !this.expand;
    },

    toggleSelectAll(checked) {
      // 正选
      if (checked) {
        this.$emit('selectAll');
      }
      // 反选
      else {
        this.$emit('update:selectedRows', []);
      }
    },

    handleSelectAll(selection) {
      let _selectedRows = _.cloneDeep(this.selectedRows);

      _selectedRows.push(...selection);

      if (this.data.some((item) => selection.every((s) => s[this.rowKey] !== item[this.rowKey]))) {
        _selectedRows = _selectedRows.filter((s) => this.data.every((item) => item[this.rowKey] !== s[this.rowKey]));
      }

      _selectedRows = _selectedRows.filter(
        (item, index, arr) => arr.findIndex((cItem) => cItem[this.rowKey] === item[this.rowKey]) === index
      );

      this.$emit('update:selectedRows', _selectedRows);
    },

    select(selection, row) {
      let _selectedRows = _.cloneDeep(this.selectedRows);

      if (_selectedRows.every((item) => item[this.rowKey] !== row[this.rowKey])) {
        _selectedRows.push(row);
      } else {
        _selectedRows = _selectedRows.filter((item) => item[this.rowKey] !== row[this.rowKey]);
      }

      this.$emit('update:selectedRows', _selectedRows);
    },

    toggleRowSelection() {
      this.data.forEach((row) => {
        this.$refs.table.toggleRowSelection(
          row,
          this.selectedRows?.some((s) => s[this.rowKey] === row[this.rowKey])
        );
      });
    },

    getRowKey(row) {
      if (this.selectedRows && !row[this.rowKey]) {
        throw new Error('rowKey 不存在！');
      }

      return row[this.rowKey];
    },

    formatter(row, col) {
      return row[col.property] || '-';
    },

    // 设置列浮动
    setColumnFixed(column) {
      if (column.label === '操作') {
        column.fixed = 'right';
      }
    },

    // 设置操作列宽度
    setOperationColumnWidth(column) {
      if (column.label === '操作') {
        const wordCount = column
          .props()
          .btnList.map((item) => item.text)
          .join('').length;
        column.width = wordCount * 14 + (column.props().btnList.length - 1) * 16 + 20 + 12;
        column.width += 16; // 加16的右padding
      }
    },

    // 设置列的最小宽度 fixme 有些时候，某列的宽度会特别宽
    setColumnMinWidth(column) {
      const labelCount = column.label.length;
      column.minWidth = labelCount * 23;

      if (column.filters) column.minWidth += 13;
    },

    handleSizeChange(pageSize) {
      this.$emit('update:pagination', {
        ...this.pagination,
        pageSize: pageSize,
      });

      this.$nextTick(() => {
        this.handlePageChange(1);
      });
    },

    handlePageChange(page) {
      this.$emit('update:pagination', {
        ...this.pagination,
        page,
      });

      this.$nextTick(() => {
        this.pagination.pageChange();
      });
    },
  },

  computed: {
    showData() {
      if (this.showExpand && !this.expand) {
        return this.data.filter((item, index) => index < 3);
      }

      return this.data;
    },

    // 先处理完columns的参数，再bind才能生效
    handleColumn() {
      return (column) => {
        this.setOperationColumnWidth(column);
        this.setColumnFixed(column);
        // this.setColumnMinWidth(column);

        return column;
      };
    },

    filterColumnProps() {
      return (column) => {
        const componentPropKeys = ['props', 'component', 'headerProps', 'headerComponent'];
        const columnkeys = _.keys(column).filter((key) => !componentPropKeys.some((property) => property === key));
        return _.pick(column, columnkeys);
      };
    },

    componentProps() {
      return (scope, column) => {
        return {
          ...column.props?.(scope.row, scope.column),
          row: scope.row,
          col: scope.col,
        };
      };
    },

    headerComponentProps() {
      return (scope, column) => {
        return {
          ...column.headerProps?.(scope.row, scope.column),
          row: scope.row,
          col: scope.col,
        };
      };
    },

    showTooltip() {
      return (column) => {
        if (column.hideTooltip) return false;
        return !(column.label === '操作');
      };
    },

    handleMaxHeight() {
      if (this.showExpand) {
        return 99999999;
      }

      return this.maxHeight;
    },
  },

  watch: {
    data() {
      this.$nextTick(() => {
        const { page, pageSize, total } = this.pagination;
        this.toggleRowSelection();

        const maxPage = Math.ceil(total / pageSize);
        if (page > maxPage && page > 1) {
          this.$emit('update:pagination', {
            ...this.pagination,
            page: page - 1,
          });

          this.pagination.pageChange();
        }
      });
    },

    // 搜索的情况
    'pagination.total'() {
      this.$emit('update:selectedRows', []);
    },

    selectedRows() {
      this.isSelectAll = this.selectedRows.length === this.pagination.total;

      if (this.selectedRows.length === 0) {
        this.$refs.table.clearSelection();
      }

      this.toggleRowSelection();
    },
  },
};
