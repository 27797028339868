<template>
  <el-dropdown trigger="click" :hide-on-click="false" placement="bottom-start">
    <span class="el-dropdown-link" :class="{ active: checkList.length }" @click="initData">
      {{ column.label }}<i class="el-icon-arrow-down el-icon--right"></i>
    </span>

    <el-dropdown-menu :id="column.columnKey" slot="dropdown">
      <el-dropdown-item class="wrapper">
        <el-checkbox
          v-for="item in column.filters"
          :key="item.value"
          @change="selectChange($event, item.value)"
          :value="isChecked(item.value)"
          :title="item.text"
        >
          {{ item.text }}
        </el-checkbox>
      </el-dropdown-item>

      <el-dropdown-item class="footer">
        <span @click="resetFilter">重置</span>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import Vue from 'vue';
import { Dropdown, DropdownMenu, DropdownItem, Checkbox } from 'element-ui';

Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Checkbox);

export default {
  name: 'header-filter',

  props: {
    column: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      checkList: [],
    };
  },

  methods: {
    // 当同时存在筛选和排序时，点文字不能写入filters，要点筛选图标才能写入数据
    initData(e) {
      const cell = e.target.parentNode.parentNode;
      const originFilterIcon = cell.querySelector('.el-table__column-filter-trigger');
      originFilterIcon.click();
    },

    selectChange(checked, val) {
      if (checked) {
        this.checkList = [...new Set(this.checkList).add(val)];
      } else {
        this.checkList = this.checkList.filter((item) => item !== val);
      }

      this.handleFilter();
    },

    handleFilter() {
      this.$emit('filterChange', {
        [this.column.columnKey]: this.checkList,
      });
    },

    resetFilter() {
      this.checkList = [];
      this.$emit('filterChange', {
        [this.column.columnKey]: this.checkList,
      });
    },
  },

  computed: {
    isChecked() {
      return (val) => {
        return this.checkList.includes(val);
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dropdown {
  cursor: pointer;

  .el-dropdown-link.active i::before {
    color: $themeColor;
  }
}

.el-dropdown-menu {
  padding: 10px 0 0 0;
  max-width: 400px;

  .el-dropdown-menu__item {
    background-color: #fff;
    overflow: hidden;

    .el-checkbox {
      display: flex;
      width: 100%;

      ::v-deep .el-checkbox__label {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.wrapper {
      max-height: 234px;
      overflow-y: auto;
      padding: 0;

      label {
        height: 35px;
        align-items: center;
        padding: 0 20px;

        &:hover {
          background-color: #f5f7fa;
        }
      }

      &::-webkit-scrollbar {
        width: 16px !important;
        height: 18px;
      }
      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
      &::-webkit-scrollbar-thumb {
        height: 50px;
        border-radius: 10px;
        background-color: #dedede;
        border: 5px solid #fff;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #dedede;
      }
    }

    &.footer {
      border-top: 1px solid #ebeef5;
      margin: 8px 0 0 0;
      line-height: 35px;
      box-sizing: border-box;
      cursor: default;

      span {
        font-size: 13px;
        color: #606266;
        cursor: pointer;

        &:hover {
          color: $themeColor;
        }
      }
    }
  }
}
</style>

<style lang="scss">
/* 隐藏默认筛选弹窗 */
.el-table-filter {
  display: none;
}
</style>
