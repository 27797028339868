/**
 * @file 设备管理 模块接口
 */
import request from '@/utils/request';
import { requestByJsonType } from './common.js';

/** 设备管理-青鹿盒子 */

/**
 * @desc 盒子列表查询
 * @param { Number } data.pageIndex    -
 * @param { Number } data.pageSize     -
 * @param { String } data.deviceValue  - 设备类型 可以不传
 */
export const getBoxPageInit = (data) => {
  return requestByJsonType({
    url: '/serv-devicemanagement/deviceInfo/list',
    method: 'POST',
    data,
  });
};

/**
 * @desc 盒子型号查询
 * @param { Number } data.type - 类型 默认是1  可不传
 */
export const getBoxModelList = (data) => {
  return request({
    url: '/serv-devicemanagement/deviceInfo/getDeviceDefinitionList',
    method: 'GET',
    data,
  });
};

/**
 * @desc 获取版本列表
 */
export const getBoxVersionList = () => {
  return request({
    url: '/serv-devicemanagement/deviceInfo/getBoxVersinList',
    method: 'GET',
  });
};

/**
 * @desc 云终端（青鹿盒子）列表分页查询（只包含盒子信息）
 * @param { Number } pageSize 每页大小
 * @param { Number } pageIndex 当前页
 * @param { Number } gradeId 年级id
 * @param { String } classId 班级id
 */
export const getBoxPageJSON = (data) => {
  return request({
    url: '/client/box/page/json.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 保存配置信息
 * @param { String } classromId 教室主键id
 * @param { String } syncTime 同步时间（包含同步类型和同步时间），两种同步时间类型分别对应两种格式，具体格式看例子
 * eg:
 * 当syncType为1 的时候：{"syncType":"1","interval":"1"}
 * 当syncType为2的时候：{"syncType":"2","times":[{"starthour":"1","startminute":"1","endhour":"1","endminute":"2"},{"starthour":"2","startminute":"2","endhour":"2","endminute":"3"}]}
 */
export const saveBox = (data) => {
  return request({
    url: '/client/box/save.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 编辑盒子，回显数据
 * @param { String } id 盒子id
 */
export const getBoxOneJSON = (data) => {
  return request({
    url: '/client/box/json/edit.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 盒子删除接口
 * @param { Number } data.deviceId - 终端id
 */
export const deleteBox = (data) => {
  return request({
    url: '/serv-devicemanagement/deviceInfo/del',
    method: 'GET',
    data,
  });
};

/**
 * @desc 新增录播设备查询教室列表
 */
export const getClassroomList = (data) => {
  return request({
    url: '/classrom/list.do',
    method: 'GET',
    data,
  });
};

/** 设备管理-点阵笔 */

/**
 * @desc 根据学校ID查询铺码方案
 * @param { Number | String } schoolId 学校id
 */
export const getSchoolPrintPlan = (data) => {
  return request({
    url: '/printPlan/getSchoolPrintPlan.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 点阵笔列表
 * @param { String } data.searchKey        - 查询关键字
 * @param { Number } data.deviceTypes      - 设备型号
 * @param { Number } data.firmwareVersions - 固件版本
 * @param { Number } data.usertypes        - (1:学生点阵笔,2:公共点阵笔，3教师笔)
 * @param { String } data.gradeIds         - 年级id集合
 * @param { String } data.classIds         - 班级id集合
 * @param { Number } data.schoolYearId     - 升学年id
 * @param { Number } data.pageIndex        -
 * @param { Number } data.pageSize         -
 * @param { String } data.userIds          - 用户id集合
 */
export const getPenPageJSON = (data) => {
  return requestByJsonType({
    url: '/serv-devicemanagement/pubDeviceDzb/selectList',
    method: 'POST',
    data,
  });
};

/**
 * @desc 查询升学年 点阵笔数据准备-异常任教数据
 * @param { Number } data.schoolYearId - 学年id
 */
export const getErrorPenPageJSON = (data) => {
  return request({
    url: '/serv-manager/basicClassRTeacher/selectExcData',
    method: 'get',
    data,
  });
};

/**
 * @desc 查询点阵笔型号
 * */
export const getPenModelList = () => {
  return request({
    url: '/serv-devicemanagement/pubDeviceDzbType/selectList',
    method: 'get',
  });
};

/**
 * @desc 查询点阵笔固件版本
 * */
export const getPenVersionList = () => {
  return request({
    url: '/serv-devicemanagement/pubDeviceDzb/listFirmwareVersion',
    method: 'get',
  });
};

/**
 * @desc 获取校层webSocket的ip和端口
 */
export const getWebSocketHost = () => {
  return request({
    url: '/dotmatrix/getWebSocketHost.do',
    method: 'GET',
  });
};

/**
 * @desc 新增或编辑点阵笔
 * @param { String } data.pubDeviceDzbUserId - 点阵笔关联表id，不传新增，传则编辑
 * @param { Number } data.dzbNo              - 点阵笔编号
 * @param { String } data.userType           - (1:学生点阵笔 3教师笔)
 * @param { Number } data.userId             - userId
 * @param { Number } data.gradeName          - 年级名称
 * @param { Number } data.gradeId            - 年级id
 * @param { Number } data.schoolYearId       - 学年id（升年级-数据准备时要传）
 */
export const savePen = (data) => {
  return requestByJsonType({
    url: '/serv-devicemanagement/pubDeviceDzb/save',
    method: 'POST',
    data,
  });
};

/**
 * @desc 批量删除点阵笔
 * @param {Array} data.pubDeviceDzbUserIds - 用户关联id集合
 * @param {String} data.schoolYearId       - 学年id
 */
export const deletePen = (data) => {
  return requestByJsonType({
    url: '/serv-devicemanagement/pubDeviceDzb/deleteBatch',
    method: 'POST',
    data,
  });
};

/**
 * @desc 根据姓名或者学号、工号查询
 * @param { String } data.search   - 查询关键字
 * @param { String } data.identity - 用户身份，1学生、2家长、3教职工
 */
export const getUserByNoOrName = (data) => {
  return request({
    url: '/serv-manager/user/selectListByNameOrIdNumber',
    method: 'GET',
    data,
  });
};

/**
 * @desc 根据教师id查询任教信息
 * @param { String } data.teacherId    - 教师id
 * @param { String } data.schoolYearId - 学年id（升年级-数据准备-点阵笔-新增需要传）
 */
export const getClassLessonGradeListByTeacher = (data) => {
  return request({
    url: '/serv-manager/basicClassRTeacher/selectTeacherInfoList',
    method: 'GET',
    data,
  });
};
