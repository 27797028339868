/**
 * @desc 青鹿盒子
 */

import { Table, TableColumn, Input, Button, Select, Option } from 'element-ui';
import qlPagination from '@/components/qlPagination';
import basicHeader from '@/components/backStageHeader';
import Dialog from './components/dialog.vue';
import ETable from '@components/table/index.vue';
import OperationBtns from '@components/table/components/operationBtns.vue';

import { getBoxPageInit, getBoxPageJSON, deleteBox, getBoxModelList, getBoxVersionList } from '@/api/equipment-manage';
import { getClassListData } from '@/api/basic';

export default {
  name: 'box',

  components: {
    ETable,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    qlPagination,
    basicHeader,
    Dialog,
  },

  data() {
    return {
      grades: [
        {
          gradeId: '',
          gradeName: '全部',
        },
      ],
      classes: [
        {
          classId: '',
          className: '全部',
        },
      ],
      table: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
        gradeId: '',
        classId: '',
      },
      dialogVisible: false,
      dialogBoxId: '',

      boxModelList: [],
      boxVersionList: [],

      filterOptions: {
        deviceValue: '', // 盒子型号
        currentBoxVer: '', // 盒子版本
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,

        pageChange: this.pageChange,
      },
    };
  },

  methods: {
    pageChange() {
      this.getTableData();
    },

    // handle 切换年级
    handleChangeGrade(val) {
      this.table.classId = '';
      this.table.pageIndex = 1;

      getClassListData({
        gradeId: val,
      }).then(({ result }) => {
        this.classes = [
          {
            classId: '',
            className: '全部',
          },
          ...result,
        ];
        this.getBoxPageJSON();
      });
    },

    // handle 配置
    handleEdit(val) {
      this.dialogVisible = true;
      this.dialogBoxId = val.id;
    },

    // handle 删除
    handleDel(val) {
      const h = this.$createElement;
      this.$msgbox({
        message: h('div', null, [
          h('h3', { class: 'T3B mg-b-13' }, '确定删除该云端吗？ '),
          h('p', { class: 'T5-2' }, '删除会影响盒子的使用。'),
        ]),
        type: 'warning',
        customClass: 'ql-message-box_notitle',
        showClose: false,
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          deleteBox({ deviceId: val.id }).then(() => {
            this.$message.success('已删除该云终端!');
            this.getTableData();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },

    getBoxPageJSON() {
      getBoxPageJSON({
        pageSize: this.table.pageSize,
        pageIndex: this.table.pageIndex,
        gradeId: this.table.gradeId,
        classId: this.table.classId,
      }).then(({ result }) => {
        // console.log(result);
        if (result.data.length === 0 && this.table.pageIndex !== 1) {
          this.table.pageIndex--;
          this.getBoxPageJSON();
          return;
        }

        this.table.list = result.data;
        this.table.total = result.recordCount;
      });
    },

    filterChangeHandler(filterObj) {
      // 可选
      this.filterOptions = {
        ...this.filterOptions,
        [Object.keys(filterObj)[0]]:
          Object.values(filterObj)[0].length > 0 ? Object.values(filterObj)[0].join(',') : undefined,
      };
    },

    async getTableData() {
      const { deviceValue, currentBoxVer } = this.filterOptions;

      const res = await getBoxPageInit({
        pageIndex: this.pagination.page,
        pageSize: this.pagination.pageSize,
        currentBoxVer: currentBoxVer ? currentBoxVer.split(',') : [],
        deviceValue: deviceValue ? deviceValue.split(',') : [],
      });

      if (res.status === 0) {
        this.table.list = res.result.data;
        this.pagination.total = +res.result.recordCount;
      }
    },

    getBoxModel() {
      getBoxModelList().then((res) => {
        if (res.status === 0) {
          this.boxModelList = res.result.map((item) => ({ text: item.deviceName, value: item.deviceValue }));
        }
      });
    },

    getBoxVersion() {
      getBoxVersionList().then((res) => {
        if (res.status === 0) {
          this.boxVersionList = res.result.map((item) => ({ text: item, value: item }));
        }
      });
    },
  },

  mounted() {
    this.getTableData();
    this.getBoxModel();
    this.getBoxVersion();
  },

  computed: {
    columns() {
      return [
        {
          label: 'IP地址',
          prop: 'ip',
        },
        {
          label: '盒子机身号',
          prop: 'machineRealNo',
        },
        {
          label: '盒子型号',
          prop: 'deviceValue',
          columnKey: 'deviceValue',
          filters: this.boxModelList,
        },
        {
          label: '盒子版本',
          prop: 'currentBoxVer',
          columnKey: 'currentBoxVer',
          filters: this.boxVersionList,
        },
        {
          label: '关联教室',
          prop: 'classroomName',
        },
        {
          label: '操作',
          props: () => ({
            btnList: [
              {
                text: '删除',
                disabled: () => this.notAllowOperate,
                handle: this.handleDel,
              },
            ],
          }),
          component: OperationBtns,
        },
      ];
    },
  },

  watch: {
    filterOptions: {
      handler() {
        this.pagination.page = 1;
        this.getTableData();
      },
      deep: true,
    },
  },
};
